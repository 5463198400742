export enum StepTypeBackend {
  Goto = 0,
  Dock = 1,
  Wait = 3,
  Lift = 4,
  Drop = 5,
  WaitForEvent = 6,
  DockToCharge = 7,
  RaiseEvent = 8,
  GoToMapping = 10,
  DockToMapping = 11,
  StartToCharge = 12,
  WaitForSideButton = 13,
  WaitForDevice = 14,
  WaitForScanDestination = 15,
  WaitForSapAcknowledgement = 16,
  TurnDolly = 17,
  GotoPose = 19,
  GotoAndPushSideButton = 20,
  StopToCharge = 22,
  PrepareForLift = 25,
}

export enum StepType {
  Goto = 100,
  Dock = 101,
  Wait = 103,
  Lift = 104,
  Drop = 105,
  DockToCharge = 107,
  GoToMapping = 110,
  DockToMapping = 111,
  StartToCharge = 112,
  WaitForEndOfStep = 113,
  WaitForSideButton = 116,
  WaitForDevice = 117,
  WaitForScanDestination = 118,
  WaitForSapAcknowledgement = 119,
  Unknown = 120,
  TurnDolly = 121,
  GotoPose = 122,
  GotoAndPushSideButton = 123,
  SAP = 124,
  StopToCharge = 126,
  FinePositioning = 127,
  WaitForTrigger = 128,
  ReleaseForTrigger = 129,
  GotoAndLift = 130,
  GotoAndDrop = 131,
  PrepareForLift = 134,
}

export enum StepTypeIcon {
  DROP = 'arrow_down',
  DOCKTOCHARGE = 'battery_charge',
  LIFT = 'arrow_up',
  GOTO = 'arrow_right',
  DOCK = 'arrows_move_horizontally',
  WAIT = 'stop',
  STARTTOCHARGE = 'battery_charge',
  WAITFORENDOFSTEP = 'time',
  WAITFORCONNECTION = 'time',
  WAITFORAMA = 'time',
  WAITFORSIDEBUTTON = 'stop',
  WAITFORDEVICE = 'time',
  WAITFORSCANDESTINATION = 'time',
  WAITFORSAPACKNOWLEDGEMENT = 'time',
  PREPAREFORLIFT = 'arrows_move_vertically',
}

export enum StepTypeAttributeType {
  Poi = 0,
  TimeSpan = 2,
  Empty = 3,
  Step = 4,
  Device = 7,
  Node = 8,
  Text = 9,
  Height = 10,
}
