import { Directive, Input, OnChanges, OnInit } from '@angular/core';
import {
  ColumnApi,
  ColumnResizedEvent,
  ColumnState,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
} from 'ag-grid-community';
import { GuidString } from 'core/models';
import * as helper from 'shared/helpers';
import { getFilter, setFilter } from 'shared/helpers';
import { PersistTableFilterAndColumnState } from '../ag-grid-directive-model';

@Directive({
  selector:
    // eslint-disable-next-line max-len
    '[appPersistTableFilterAndColumnState]',
  standalone: true,
})
export class PersistTableFilterAndColumnStateDirective implements OnInit, OnChanges {
  @Input() set appPersistTableFilterAndColumnState({
    persistKey,
    persistFilter,
    persistColumnSetting,
    selectedWorkingAreaId,
    excludeFilterKeys,
    excludeColumnKeys,
    gridOptions,
    rowData,
    sizeColumnsToFit,
  }: PersistTableFilterAndColumnState) {
    this.persistKey = persistKey;
    this.persistFilter = persistFilter ?? this.persistFilter;
    this.persistColumnSetting = persistColumnSetting ?? this.persistColumnSetting;
    this.selectedWorkingAreaId = selectedWorkingAreaId;
    this.excludeFilterKeys = excludeFilterKeys ?? this.excludeFilterKeys;
    this.excludeColumnKeys = excludeColumnKeys ?? this.excludeColumnKeys;
    this.gridOptions = gridOptions;
    this.rowData = rowData;
    this.sizeColumnsToFit = sizeColumnsToFit ?? false;
  }

  selectedWorkingAreaId: GuidString | undefined | null;
  excludeFilterKeys: string[] = [];
  excludeColumnKeys: string[] = [];
  persistKey!: string;
  persistFilter = true;
  sizeColumnsToFit = false;
  persistColumnSetting = true;
  gridOptions!: GridOptions;
  rowData: unknown[] | undefined;

  ngOnInit(): void {
    const defaultOnFilterChanged = this.gridOptions.onFilterChanged;
    const defaultOnFirstDataRendered = this.gridOptions.onFirstDataRendered;
    const defaultOnSortChanged = this.gridOptions.onSortChanged;
    const defaultOnColumnVisible = this.gridOptions.onColumnVisible;
    const defaultOnColumnResized = this.gridOptions.onColumnResized;
    const defaultOnColumnMoved = this.gridOptions.onColumnMoved;
    const defaultOnModelUpdated = this.gridOptions.onModelUpdated;
    const defaultOnGridReady = this.gridOptions.onGridReady;

    // Necessary to allow parent components to define additional functions for those events
    this.gridOptions.onFilterChanged = (...args) => {
      defaultOnFilterChanged && defaultOnFilterChanged(...args);
      this.onFilterChanged.call(this, ...args);
    };
    this.gridOptions.onFirstDataRendered = (...args) => {
      defaultOnFirstDataRendered && defaultOnFirstDataRendered(...args);
      this.onFirstDataRendered.call(this, ...args);
    };
    this.gridOptions.onSortChanged = (...args) => {
      defaultOnSortChanged && defaultOnSortChanged(...args);
      this.onSortChanged.call(this);
    };
    this.gridOptions.onColumnVisible = (...args) => {
      defaultOnColumnVisible && defaultOnColumnVisible(...args);
      this.onColumnVisible.call(this);
    };
    this.gridOptions.onColumnResized = (...args) => {
      defaultOnColumnResized && defaultOnColumnResized(...args);
      this.onColumnResized.call(this, ...args);
    };
    this.gridOptions.onFirstDataRendered = (...args) => {
      defaultOnFirstDataRendered && defaultOnFirstDataRendered(...args);
      this.onFirstDataRendered.call(this, ...args);
    };
    this.gridOptions.onColumnMoved = (...args) => {
      defaultOnColumnMoved && defaultOnColumnMoved(...args);
      this.onColumnMoved.call(this);
    };
    this.gridOptions.onModelUpdated = (...args) => {
      defaultOnModelUpdated && defaultOnModelUpdated(...args);
      this.onModelUpdated.call(this, ...args);
    };
    this.gridOptions.onGridReady = (...args) => {
      defaultOnGridReady && defaultOnGridReady(...args);
      this.onGridReady.call(this, ...args);
    };
  }

  onFilterChanged(event: FilterChangedEvent): void {
    if (this.persistFilter) {
      let filterModel = event.api.getFilterModel();

      if (this.excludeFilterKeys.length > 0) {
        filterModel = this.filterFilterModel(filterModel);
      }
      setFilter(filterModel, this.persistKey, this.selectedWorkingAreaId);
    }
  }

  onFirstDataRendered(event: FirstDataRenderedEvent): void {
    if (this.persistFilter) {
      const filterModel = getFilter(this.persistKey, this.selectedWorkingAreaId);

      if (filterModel) {
        event.api.setFilterModel(filterModel);

        if (Object.keys(filterModel).length === 0) {
          this.autoSizeAllColumns();
          this.setColumnSetting(this.persistKey);
        }
      }
    }
  }

  ngOnChanges({
    appPersistTableFilterAndColumnState,
  }: TypedChanges<PersistTableFilterAndColumnStateDirective>): void {
    // Whenever we change the selected work area the rowData becomes an empty array before getting
    // the latest rowData. When the row data is empty a filter model cannot be applied.
    // This check is to ensure that we update the filter model whenever an empty rowData array was
    // populated
    if (
      appPersistTableFilterAndColumnState?.previousValue?.rowData?.length === 0 &&
      appPersistTableFilterAndColumnState.currentValue?.rowData?.length !== 0
    ) {
      this.updateFilterModel();
    }
  }

  updateFilterModel(): void {
    let filterModel = getFilter(this.persistKey, this.selectedWorkingAreaId);

    if (filterModel) {
      if (this.excludeFilterKeys.length > 0) {
        filterModel = this.filterFilterModel(filterModel);
      }

      if (Object.entries(filterModel).length === 0) {
        this.autoSizeAllColumns();
      }
    }

    this.gridOptions.api?.setFilterModel(filterModel);
  }

  filterFilterModel(filterModel: { [key: string]: {} }): { [key: string]: {} } {
    return Object.fromEntries(
      Object.entries(filterModel).filter(([filter, _]) => !this.excludeFilterKeys.includes(filter))
    );
  }

  filterColumnModel(columnStates: ColumnState[] | undefined): ColumnState[] | undefined {
    return columnStates?.filter(columnState => !this.excludeColumnKeys.includes(columnState.colId));
  }

  autoSizeAllColumns(): void {
    if (!this.sizeColumnsToFit) return;

    this.gridOptions.columnApi?.autoSizeAllColumns();
  }

  getPersistColumnSettings(columnApi: ColumnApi): void {
    if (this.persistKey) {
      const existingColumnSettings = helper.getColumnSetting(this.persistKey);

      if (existingColumnSettings) {
        columnApi.applyColumnState({ state: existingColumnSettings, applyOrder: true });
      }
    }
  }

  setColumnSetting(persistKey: string): void {
    if (!this.persistColumnSetting) return;

    const columnState = this.gridOptions.columnApi?.getColumnState();
    const filteredColumnState = this.filterColumnModel(columnState);

    if (filteredColumnState) {
      helper.setColumnSetting(persistKey, filteredColumnState);
    }
  }

  onSortChanged(): void {
    this.setColumnSetting(this.persistKey);
  }

  onColumnVisible(): void {
    this.setColumnSetting(this.persistKey);
  }

  onColumnResized(event: ColumnResizedEvent): void {
    // the flex setting in the gridOptions triggers a column resize which we want to ignore for persisting the column settings
    if (event.source !== 'flex') {
      this.setColumnSetting(this.persistKey);
    }
  }

  onColumnMoved(): void {
    this.setColumnSetting(this.persistKey);
  }

  onModelUpdated(event: ModelUpdatedEvent): void {
    if (event.api.getDisplayedRowCount() === 0) {
      this.autoSizeAllColumns();
    }
  }

  onGridReady(event: GridReadyEvent): void {
    if (this.persistColumnSetting) {
      this.getPersistColumnSettings(event.columnApi);
    }
  }
}
